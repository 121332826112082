<template>
	<div>
		<!-- 公共头部 -->
	<Header></Header>
		
		<!-- <div class="section1">
			<router-link :to="'/train?id='+item.id" class="item " v-for="(item,index) in cateList" :class="[ $route.query.id == item.id ? 'active':'' ]" :key="index">{{item.title}}</router-link>
		</div> -->
		<!-- <div class="blank_80"></div> -->
		
		<div class="section2">
			<ul class="list">
				<router-link :to="'/trainInfo?id='+item.cate_id+'&aid='+item.id" class="item" v-for="(item,index) in newsList" :key="index">
					<div class="pic">
						<img class="img" :src="item.img" />
					</div>
					<div class="content">
						<h2 class="title">{{item.title}}</h2>
						<div class="desc">{{item.description}}</div>
					</div>
				</router-link>
			</ul>
			<wd-pagination v-model="page" :total="total"></wd-pagination>
		</div>
		<!-- 公共底部 -->
		<!-- <Footer></Footer> -->
	</div>
</template>

<script>
	import Header from '@/components/Public/Header.vue';
	import Footer from '@/components/Public/Footer.vue';
	export default {
		components: {
			Header,
			Footer
		},
		data() {
			return {
				title: '',
				cateList: [],
				page: 1,
				limit: 10,
				total: 0,
				newsList: []
			}
		},
		watch: {
			$route(to,form) {
				if((to.path == form.path) && (this.$route.path == '/train')){
					if(this.page == 1){
						this.getNewsList();
					} else {
						this.page = 1;
					}
				}
			},
			page() {
				if(this.$route.path == '/train'){
					this.getNewsList();
				}
			}
		},
		created() {
			this.getCateList();
			this.getNewsList();
		},
		methods: {
			async getCateList() {
				this.$toast.loading('加载中...');
				const { data:res } = await this.$http.get('/subject/cate');
				this.$toast.close();
				if(this.$store.state.beta){
					console.log('培训项目公用：' + new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds() , res);
				}
				var code = res.code;
				if(code == 1) {
					this.cateList = res.data;
				} else {
					this.cateList = [];
				}
			},
			async getNewsList() {
				var cate_id = this.$route.query.id || 0;
				var page = this.page;
				var limit = this.limit;
				var data = {
					cate_id, page, limit
				}
				this.$toast.loading('加载中...');
				const { data:res } = await this.$http.get('/subject/lists', { params: data });
				this.$toast.close();
				if(this.$store.state.beta){
					console.log('培训项目列表：' + new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds() , res);
				}
				var code = res.code;
				if(code == 1) {
					var total = res.data.count;
					this.total = total;
					var newsList = res.data.lists;
					for(var i = 0; i < newsList.length; i++) {
						if(newsList[i]['img'] && newsList[i]['img'].substring(0,4) != 'http'){
							newsList[i]['img']=this.$store.state.http + newsList[i]['img']
						}
					}
					this.newsList = newsList;
				} else {
					this.newsList = [];
				}
			}
		},
	};
</script>

<style scoped>
	.section1{
		white-space: nowrap;
		overflow-x: scroll;
		background: #F3F3F3;
		box-shadow: 0 0 0.1rem rgba(0,0,0,.2);
		position: fixed;
		width: 100%;
		left: 0;
		top: 1rem;
	}
	.section1 .item{
		font-size: 0.28rem;
		color: #888888;
		display: inline-block;
		vertical-align: top;
		height: 0.8rem;
		line-height: 0.8rem;
		padding: 0 0.3rem;
	}
	.section1 .item.active{
		position: relative;
		font-size: 0.32rem;
		color: #FFFFFF;
		background: #86cb08;
	}
/* 	.section1 .item.active::after{
		content: '';
		display: inline-block;
		position: absolute;
		width: 0.48rem;
		height: 0.04rem;
		background: #ff6000;
		bottom: 0.08rem;
		border-radius: 0.02rem;
		left: 50%;
		transform: translate(-50%,0);
		-ms-transform: translate(-50%,0);
		-o-transform: translate(-50%,0);
		-moz-transform: translate(-50%,0);
		-webkit-transform: translate(-50%,0);
	} */
	.section2{
		margin-top: 0.3rem;
		margin-bottom: 0.8rem;
	}
	.section2 .list{
		background: #ffffff;
		box-shadow: 0 0 0.1rem rgba(0,0,0,.2);
		padding: 0 0.3rem;
		margin-bottom: 0.3rem;
	}
	.section2 .list .item{
		padding: 0.3rem 0;
		border-top: 0.02rem solid #e0e0e0;
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
	}
	.section2 .list .item:first-child{
		border-top: none;
	}
	.section2 .list .item .pic{
		width: 2.2rem;
		height: 1.6rem;
		background: #ffffff;
		box-shadow: 0 0 0.1rem rgba(0,0,0,.2);
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.section2 .list .item .pic .img{
		max-height: 100%;
	}
	.section2 .list .item .content{
		width: 4.4rem;
	}
	.section2 .list .item .content .title{
		font-size: 0.3rem;
		color: #333333;
		margin-bottom: 0.1rem;
	}
	.section2 .list .item .content .desc{
		font-size: 0.26rem;
		color: #969897;
		line-height: 0.4rem;
		max-height: 1.2rem;
		overflow: hidden;
	}
</style>